<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <!-- <PageLoader /> -->

    <v-layout
      wrap
      justify-center
      style="font-family: 'Nunito Sans', sans-serif"
    >
      <v-flex xs12 sm10 lg10 py-6>
        <!-- <vue-element-loading
          :active="appLoading"
          :is-full-screen="true"
          background-color="#FFFFFF"
          color="blue"
          spinner="mini-spinner"
        /> -->
        <v-card class="mx-auto elevation-2" align="center" outlined>
          <v-layout xs10 wrap class="d-flex px-4 mt-6 mx-0">
            <v-flex
              xs12
              sm6
              md7
              lg9
              class="text-left lg-0 lg-pl-6"
              style="font-weight: 700; font-size: 23px"
            >
              <v-icon class="pr-4" color="#9A8CFC">mdi-school-outline</v-icon
              >Student List
            </v-flex>

            <!-- <v-spacer></v-spacer> -->
            <v-flex xs12 sm6 md5 lg3 xl4 align-self-center text-right>
              <v-text-field
                v-model="keyword"
                clearable
                hide-details
                label="Search Name/Ad.No"
                outlined
                class="rounded-xl"
                dense
                @input="search"
              >
              </v-text-field>
            </v-flex>
            <!-- <v-flex xs12 lg3 xl1 pl-2 pt-2 pt-lg-0 align-self-center text-right>
            <v-btn
              block
              class="rounded-xl"
              @click="search()"
              style="font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              dense
              depressed
              >SEARCH</v-btn
            >
          </v-flex> -->
          </v-layout>
          <!-- <v-flex xs12 class="d-flex pa-6 mx-0" outlined> -->
          <v-layout wrap justify-start pa-3>
            <v-flex xs12 sm6 md2 lg2 pb-sm-4 pr-sm-2 pb-md-0 pr-md-0 pb-2>
              <v-select
                :items="classList"
                v-model="cls"
                label="Class"
                item-text="standardName"
                item-value="_id"
                class="rounded-xl"
                clearable
                hide-details
                outlined
                @input="search()"
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md2 lg2 pl-sm-2 pb-sm-4 pb-md-0 pb-2>
              <v-select
                :items="divisionList"
                v-model="div"
                clearable
                hide-details
                label="Division"
                item-text="divisionName"
                item-value="_id"
                class="rounded-xl"
                outlined
                @input="search()"
                dense
              ></v-select>
            </v-flex>
            <v-flex xs12 sm6 md2 lg2 pl-md-2 pr-sm-2 pr-md-0 pb-2 pb-sm-0>
              <v-select
                :items="SchoolList"
                v-model="school"
                label="School"
                item-text="SchoolName"
                item-value="_id"
                class="rounded-xl"
                clearable
                hide-details
                outlined
                @input="search()"
                dense
              ></v-select>
            </v-flex>

            <v-flex
              xs12
              sm6
              md2
              lg2
              pl-md-2
              pl-sm-2
              pl-md-0
              v-if="appUser.usertype == '1'"
            >
              <v-select
                :items="HostelList"
                v-model="hostel"
                label="Hostel"
                class="rounded-xl"
                item-text="hostelName"
                item-value="_id"
                clearable
                hide-details
                outlined
                @input="getHostelWarden()"
                dense
              ></v-select>
            </v-flex>

            <v-flex
              xs12
              sm6
              md2
              lg2
              pl-md-2
              pl-sm-2
              pl-md-0
              v-if="appUser.usertype == '1'"
            >
              <v-select
                v-if="appUser.usertype == 1"
                :items="hostelwardenList"
                v-model="hostelwarden"
                label="Warden"
                class="rounded-xl"
                item-text="name"
                item-value="_id"
                clearable
                hide-details
                @input="getBlockid()"
                outlined
                dense
              ></v-select>
            </v-flex>
            <v-flex
              xs12
              sm6
              md2
              lg2
              pl-md-2
              pl-sm-2
              pl-md-0
              v-if="appUser.usertype == '1'"
            >
              <v-select
                v-if="appUser.usertype == 1"
                :items="BlockIds"
                v-model="hostelblockid"
                label="Select Block"
                class="rounded-xl"
                item-text="Block"
                item-value="_id"
                hide-details
                outlined
                dense
              ></v-select>
            </v-flex>
          </v-layout>
          <!-- <v-layout wrap justify-space-around pa-3>
          <v-flex xs12 lg3>
            <v-select
              :items="classList"
              v-model="cls"
              label="Class"
              item-text="standardName"
              item-value="_id"
              outlined
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 lg3>
            <v-select
              :items="divisionList"
              v-model="div"
              label="Division"
              item-text="divisionName"
              item-value="_id"
              outlined
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 lg3>
            <v-select
              :items="SchoolList"
              v-model="school"
              label="school"
              item-text="SchoolName"
              item-value="_id"
              clearable
              outlined
              @input="search()"
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 lg2>
            <v-btn
              @click="search()"
              style="font-size: 16px; font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              depressed
              large
              >SEARCH</v-btn
            >
          </v-flex>
        </v-layout> -->
          <!-- </v-flex> -->
          <v-divider></v-divider>
<div v-if="allData">
          <v-layout wrap justify-start v-if="allData.length>0">
           
            <v-flex xs12 sm6 lg4 pa-4 v-for="(item, i) in allData" :key="i">
              <v-card :elevation="0">
                <v-layout wrap justify-start>
                  <v-flex xs2 class="py-4">
                    <p
                      class="ma-0"
                      style="font-size: 16px; color: #9d9d9d; font-weight: 600"
                    >
                      {{ 50 * (page - 1) + (i + 1) }}
                    </p>
                  </v-flex>

                  <v-flex xs3 align-self-start class="py-2">
                    <v-avatar>
                      <img
                        :src="`https://picsum.photos/id/${i}/200/300`"
                        alt="John"
                        class="pa-1"
                      />
                    </v-avatar>
                  </v-flex>
                  <v-flex xs7 align-self-start text-left class="py-4">
                    <router-link
                      style="text-decoration: none"
                      :to="'/studentOutpass?id=' + item._id"
                    >
                      <span
                        style="
                          font-size: 16px;
                          font-weight: 400;
                          color: #9d9d9d;
                        "
                      >
                        {{ item.name }} <br />
                        <span style="font-size: 14px">
                          <b> Ad.No {{ item.admissionNo }} </b>
                        </span>
                      </span>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
          <v-layout v-else>
             <v-container style="height: 400px">
              <v-layout wrap
                fill-height
                align-content-center
               justify-center
              >
                <v-flex class="text-subtitle-1 text-center" xs12>
                  Getting student data
                </v-flex>
                <v-flex xs6>
                  <v-progress-linear
                    color="deep-purple accent-4"
                    indeterminate
                    rounded
                    height="6"
                  ></v-progress-linear>
                </v-flex>
              </v-layout>
            </v-container>
          </v-layout>
</div>
        </v-card>
        <v-layout wrap justify-center>
          <v-flex xs6>
            <v-pagination
              small
              v-model="page"
              :total-visible="7"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import PageLoader from './../../components/PageLoader'
import store from "./../../store";
import axios from "axios";
export default {
  //  components: {
  //   PageLoader
  // },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      isSelectAll: false,
      mapdialoge: false,
      selected: [],
      sflag: true,
      container1:false,
      allData: [],
      SchoolList: [],
      HostelList: [],
      hostel: null,
      hostel1: null,
      hostelwardenList: [],
      hostelwarden: null,
      hostelBlockList: [],
      hostelblock: null,
      hostelblockid: null,
      BlockIds: [],
      div: null,
      cls: null,
      school: null,
      maphostel: null,
      classList: null,
      divisionList: null,
      keyword: null,
      page: 1,
      Pagelength: 0,
    };
  },

  watch: {
    cls() {
      this.page = 1;
      this.getDivision();
    },
    div() {
      this.page = 1;

      if (this.cls && this.div) this.search();
    },
    page() {
      this.isSelectAll = false;
      this.search();
    },
    hostel1() {
      this.page = 1;

      // this.search();
    },
    school() {
      this.search();
    },
    hostel() {
      this.search();
    },
    hostelwarden() {
      this.search();
    },
    hostelblockid() {
      this.search();
    },
  },
  computed: {
    appLogin() {
      return store.state.userData;
    },
    appUser() {
      return store.state.userData;
    },
  },
  mounted() {
    this.search();
    this.gethostel();
    // this.getData();
    this.getclass();
    if (this.cls) this.getDivision();
    this.getschool();
    // this.getDivision()
  },
  methods: {
    gethostel() {
      axios({
        method: "get",
        // url: "/hostel/name/getlist",
        url: "main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getHostelWarden() {
      // this.appLoading = true;
      axios({
        method: "get",
        url: "hostel/warden/getlist/hostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mainHostelId: this.hostel,
          // page: this.page,
          // limit: 20,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.hostelwardenList = response.data.data;
            // this.getData();
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          // this.appLoading = false;
          console.log(err);
        });
    },
    getHostelBlock() {
      // this.appLoading = true;
      axios({
        method: "get",
        url: "/subhostel/name/getlist/mainhostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mainHostelId: this.hostel1,
          // page: this.page,
          // limit: 20,
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status == true) {
            this.hostelBlockList = response.data.data;
            // this.getData();
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          // this.appLoading = false;
          console.log(err);
        });
    },
    markAll() {
      if (this.isSelectAll) {
        this.selected = [];
        this.sflag = false;
        for (let i = 0; i < this.allData.length; i++) {
          this.allData[i].checkbox = true;
          this.selected.push(this.allData[i]._id);
        }
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      } else {
        this.sflag = false;
        for (let i = 0; i < this.allData.length; i++) {
          this.allData[i].checkbox = false;
          this.selected = [];
        }
        // console.log("Ddata=", this.isSelectAll);
        this.sflag = true;
      }
    },
    selectThis(val, id) {
      this.isSelectAll = false;
      if (val == true) {
        if (this.selected.length > 0) {
          if (!id.includes(this.selected)) {
            this.selected.push(id);
            console.log("ll=");
          }
        } else {
          this.selected.push(id);
        }
      } else {
        var ar = this.selected;
        this.selected = ar.filter((x) => x != id);
      }
    },
    getclass() {
      axios({
        method: "get",
        // url: "/view/class",
        url: "/standard/getlist",

        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.classList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getschool() {
      axios({
        method: "get",
        url: "/school/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.SchoolList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getDivision() {
      axios({
        method: "get",
        // url: "/view/division",
        url: "/division/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.cls,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.divisionList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getBlockid() {
      (this.hostelblockid = null),
        // this.appLoading = true;
        axios({
          method: "post",
          url: "/warden/hostelblock/getlist",

          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            wardenid: this.hostelwarden,
          },
        })
          .then((response) => {
            // this.appLoading = false;
            if (response.data.status == true) {
              if (response.data.hosteldata) {
                if (response.data.hosteldata.length > 0) {
                  this.BlockIds = response.data.hosteldata;
                  if (this.usertype1 == true) {
                    this.hostelblockid = response.data.hosteldata[0]._id;
                    //  this.getData();
                  }
                  //  this.hostelblockid = response.data.hosteldata[0]._id;
                  //  this.getData();
                }
              }
              // this.Pagelength = Math.ceil(response.data.totalLength / 50);
            }
            //  else {
            //   this.appTimes();
            // }
          })
          .catch((err) => {
            // this.appLoading = false;
            console.log(err);
            this.ServerError = true;
          });
    },
    // gethostel() {
    //   axios({
    //     method: "get",
    //     // url: "/hostel/name/getlist",
    //     url: "main/hostel/name/getlist",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status == true) {
    //         this.HostelList = response.data.data;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err());
    //     });
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        // url: "/student/getalllist/v1",
        url: "/student/getalllist/hostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          mainHostelId: this.hostel,
          wardenId: this.hostelwarden,
          blockId: this.hostelblockid,
          page: this.page,
          limit: 50,
          // blockId: this.hostelblock,
        },
      })
        .then((response) => {
          this.appLoading = false;

          if (response.data.status == true) {
            this.allData = response.data.data;
            this.name = response.data.name;
            this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
        })
        .catch((err) => {
          console.log(err());
          this.appLoading = false;
        });
    },
    search() {
      axios({
        method: "get",
        // url: "/student/getalllist/v1",
        url: "/student/getalllist/hostelwise",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          division: this.div,
          standard: this.cls,
          school: this.school,
          page: this.page,
          limit: 50,
          keyword: this.keyword,
          hostelname: this.hostel,
          wardenId: this.hostelwarden,
          blockId: this.hostelblockid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.allData = response.data.data;
            this.name = response.data.name;
            this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    mapData() {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/map/multiplestudent/hostel",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          // id: this.curId,
          studentid: this.selected,
          page: this.page,
          limit: 20,
          // hostelId: this.maphostel,
          mainHostelId: this.hostel1,
          blockId: this.hostelblock,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.mapdialoge = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.outpass = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.msg = response.data.msg;
            this.mapdialoge = false;
            this.showsnackbar = true;
          }
          this.search();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field {
  min-height: 10px !important;
}
</style>